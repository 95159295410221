import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Message from './Message';
import overdueIcon from "../../assets/images/icons/overdueIcon.png";
import newActivityIcon from "../../assets/images/icons/newActivityIcon.png";

interface TaskCardProps {
  title: string;
  count: number;
  icon: React.ElementType;
  gradientColors: [string, string];
  backGroundColor: [string, string];
  status: number[];
  onCardClick: (status: number[], isOpenCard: boolean, isFilterByNewActivity: boolean) => void;
  isSelected: boolean;
  isOpenCard: boolean;
  isFilterByNewActivity: boolean
}

const TaskCard: React.FC<TaskCardProps> = ({ title, count, icon: Icon, gradientColors, backGroundColor, status, onCardClick, isSelected, isOpenCard, isFilterByNewActivity }) => {

  const [isHovered, setIsHovered] = useState(false);


  const styles = {
    wrapper: {
      position: 'relative' as const,
      borderRadius: '14px',
      padding: '2px', // Thin border thickness
      background: `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})`,
      overflow: 'hidden',
      width: '264px',
      height: '184px',
      cursor: 'pointer'
    },
    '&:hover': {
      background: `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})`,
    },
    card: {
      position: 'relative' as const,
      borderRadius: '14px', // Slightly smaller radius to fit within the gradient border
      padding: '12px',
      background: isSelected || isHovered? `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})` : '#F7F9FF', // Inner content background
      textAlign: 'center' as const,
      width: '264px',


    },
    title: {
      fontSize: '18px',
      fontWeight: 'normal' as const,
      color: '#333',
    },
    taskCount: {
      fontSize: '36px',
      fontWeight: 'bold' as const,
      color: '#333',
      margin: '8px 0',
    },
    link: {
      fontSize: '14px',
      color: '#0072ff',
      textDecoration: 'none',
    }
  };


  return (

    <Grid container style={styles.wrapper} onClick={() => onCardClick(status, isOpenCard, isFilterByNewActivity)}
      onMouseOver={(e) => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false)
      }}>
      <Grid container direction='column' style={styles.card}>
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Grid item sx={{ fontWeight: 'bold', fontSize: '26px' }}>{title}</Grid>
          <Grid item>
            {title === "Overdue Tasks" ?
              <img src={overdueIcon} width='36px' height='36px' alt='icon'/>
              : isFilterByNewActivity ?
              <img src={newActivityIcon} width='26px' height='24px' alt='icon'/>
              
              :
              <Icon fontSize='medium' style={{ width: '26px', height: '26px' }} />
            }
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='flex-end' justifyContent='space-between' paddingTop={1}>
          <Grid item sx={{ fontWeight: 'bold', fontSize: '64px' }}>{count}</Grid>
          <Grid item
            sx={{
              color: 'text.secondary',
              position: 'absolute',
              bottom: '43px',
              right: '16px',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            <Message id="task.view-tasks" className='Field-input font-weight-400 labelsLinks neutral-5 underline-text' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default TaskCard;

